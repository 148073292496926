@import "~assets/styles/abstracts";

.wrapper {
  @include flex-align-center;
  gap: 15px;

  bottom: 15px;
  right: 0;
  position: absolute;

  button {
    font-weight: 700;
    color: $primary;
    svg {
      transform: scale(0.8);
    }
  }
}
