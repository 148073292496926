@import "~assets/styles/abstracts/variables";

a,
button {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.danger {
  color: var(--sError);
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.italic {
  font-style: italic;
}

.capitalize {
  text-transform: capitalize !important;
}

.bold {
  font-weight: 700;
}

.decoration-none {
  text-decoration: none;
}

.link {
  color: $primary;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.bg_light {
  background: #f6f6f7;
}

.dark {
  color: #282f38;
}
.green {
  color: #8cc152;
}
.orange {
  color: #eeac00;
}
.brown {
  color: #c19e7a;
}
.primary-color,
.color {
  color: $primary;
}
.light {
  color: #777b82;
}
.light_blue {
  color: #b2c3ee;
}
.light_label {
  color: #696969;
}
.black {
  color: #000;
}
.white {
  color: #fff;
}
.primary-light {
  color: var(--sPrimaryLight);
}

.bg_white {
  background: #fff;
}
.bg_light {
  background: #f6f6f7;
}
.bg_danger {
  background: #e9573f;
}
.bg_gray {
  background: #777b82;
}
.bg_success,
.bg_green {
  background: #8cc152;
}
.bg_success_light {
  background: #e5f9cf;
}

.bg_warning {
  background: #eeac00;
}
.bg_warning_light {
  background: #fdf9eb;
}

.bg_primary {
  background: #091b44;
}
.bg_primary_light {
  background: #e8f0fd;
}
.bg_light_blue {
  background: #e8f0fd;
}

.dark-blue {
  color: #001f60;
}

.blood-color {
  color: #6b0a0a;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100% !important;
}

.pointer {
  cursor: pointer;
}

.object-cover {
  object-fit: cover;
}

.rounded-full {
  border-radius: 50%;
}

.dashed {
  height: 2px;
  border-bottom: 2px dashed #cdd1dd;
}

.hr {
  height: 1px;
  border-bottom: 1px solid #b6bccd;
}

.hr-color {
  color: #b6bccd;
}

.ant-btn {
  box-shadow: none;
}

.flex-auto {
  flex: 1 1 auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ant-btn-primary:not(:disabled) {
  background: $primary;
  &:hover {
    background: $DarkPrimaryHover !important;
  }
}

.ant-btn-link:not(:disabled) {
  color: $primary;
  &:hover {
    color: $DarkPrimaryHover !important;
  }
}

.fullscreen-enabled {
  overflow: auto;
  padding: 30px;
  background: #fffffffa;

  .apartment-view__body {
    min-width: 290px;
    height: 500px;
  }
}

.flat-details-modal {
  .ant-card-body {
    width: 100%;
  }
}

.display-none {
  display: none;
}

.heading {
  padding: 8px 7px;
  border-radius: 10px;
  border: 1px solid $Mischka;
  background: #fff;
  margin-bottom: 40px;
}

.full-container {
  padding: 0 50px;
  max-width: 100vw;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
}

.ant-popover {
  z-index: 900;
}
