@font-face {
  font-family: 'PT Sans';
  src: url('~assets/fonts/PTSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  src: url('~assets/fonts/PTSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
