.main {
	position: relative;
	z-index: 1;
	border-radius: 4px;
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--cardBg);
	overflow: hidden;
	border-radius: 10px;
	border: 1px solid var(--01-colors-10, #cdd1dd);

	.goBack {
		position: absolute;
		left: 8px;
		top: 50%;
		transform: translateY(-50%);
	}

	.title {
		font-size: 26px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
}
