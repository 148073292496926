@import "assets/styles/abstracts";

.apartment-view {
  &__wrapper {
    width: fit-content;
  }

  &__head {
    margin: 0 auto 10px auto;
    width: fit-content;

    display: flex;
    align-items: center;
    gap: 15px;

    border-radius: 16px;
    background: #f8f9fe;
    padding-right: 16px;
  }

  &__entrance-num {
    @include flex-both-center;
    color: #1f2024;
    font-size: 16px;
    font-weight: 700;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background: #e8e9f1;
  }
  &__entrance-name {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.28px;
    text-transform: uppercase;
  }
  &__body {
    @include customScrollbar($size: 7px, $primaryColor: #a6add0);

    display: grid;
    background: #fff;
    // padding-right: 16px;
    border-radius: 10px;
    border: 1px dashed #c5c6cc;
    background: #fcfcff;
    min-width: 145px;
    height: 365px;
    width: fit-content;
    max-width: 200px;
    overflow: auto;
  }
  &__body-empty {
    @include flex-both-center;
    padding-right: 0;

    .apartment-view__inner {
      height: auto !important;
    }
  }
  &__inner {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    // width: fit-content;
  }
  &__flat-box {
    @include flex-both-center;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    &:hover {
      color: #fff !important;
    }
  }
  &__row-floor {
    position: relative;
    min-width: 100%;
    gap: 15px;
    padding-right: 15px;

    &:not(:first-child) {
      button {
        margin-block: 16px;
      }
    }
    &-basement {
      &,
      .apartment-view__num-floor {
        background: #f2f6fc;
      }

      &:first-child {
        border-top: 1px solid #e6e9ed;
      }
    }
  }
  &__num-floor {
    @include flex-both-center;
    gap: 2px;
    z-index: 3;
    background: #fff;
    height: 100%;
    position: sticky;
    left: 0;
    min-width: 30px;

    padding-block: 12.5px;
  }

  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
}
