.preloader {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	margin: auto;
	background-color: rgba(#fff, 0.9);
	overflow: hidden;
	z-index: 999999999;
	// transition: all 0.3s ease-in-out;
	.preloaderContainer {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.loaderCircleSm {
			position: absolute;
			top: 50%;
			left: 50%;
			// transform: translate(-50%, -50%);
			margin-left: -100px;
			margin-top: -100px;
			width: 200px;
			height: 200px;
			border: 3px solid rgba(255, 255, 255, 0.1);
			border-top: 4px dotted #326bcd;
			border-bottom: 4px dotted #326bcd;
			border-radius: 50%;
			animation: spinsmall 1.5s linear infinite;
		}
		.preloaderLogo {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: auto;
			width: 120px;
			height: 120px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url("~assets/images/svg/common/logo.svg");
		}
	}
	&.hidden {
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes spinsmall {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

@keyframes zoom {
	0% {
		transform: translate(-50%, -50%) scale(1);
	}
	50% {
		transform: translate(-50%, -50%) scale(0.8);
	}
	100% {
		transform: translate(-50%, -50%) scale(1);
	}
}
