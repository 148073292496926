@import "~assets/styles/abstracts";

.main {
  z-index: 1;
  position: relative;
  border-radius: 4px;
  background: #fff;

  &::before {
    content: "";
    transform: translateX(-50%);
    position: absolute;
    z-index: -1;
    top: 17px;
    left: 50%;
    width: 100%;
    height: 100%;
    opacity: 0.06;
    filter: blur(26px);
    border-radius: 4px;
    background: $primary;
  }

  .list {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #fff;

    .item {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 60px;
      border-right: 1px solid #cde1ff;
      border-left: 1px solid #cde1ff;

      color: var(--sPrimaryDark);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 228.571% */
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.3s ease;

      &.active,
      &:hover {
        color: var(--sPrimaryLight);
      }

      &:first-child {
        border-left-color: transparent;
      }
      &:last-child {
        border-right-color: transparent;
      }
    }
  }
}
