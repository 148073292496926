@import "~assets/styles/abstracts";

.header {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 10;
  position: relative;
  padding: 5px 0px;
  min-height: 80px;
  border-bottom: 5px solid $primary;

  & > * {
    width: 100%;
  }

  h3 {
    font-size: 19px;
    line-height: 21px;
  }

  @media (max-width: 536px) {
    .logo {
      gap: 0;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 5px;
    }
  }
  @media (max-width: 364px) {
    .logo {
      justify-content: center;
    }
  }
}

.profile {
  padding-inline: 0;

  svg {
    border: 2px solid $primary;
    border-radius: 50%;
    transition: all 0.3s ease 0s;
  }

  &:hover {
    svg {
      border-color: $Raven !important;
    }
  }
}
