.tabs {
	gap: 7px;
	padding: 4px;
	align-items: center;
	display: inline-flex;
	justify-content: center;
	background: #f8f9fe;
	border-radius: 50px;

	.item {
		cursor: pointer;
		position: relative;
		gap: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 160px;
		padding: 8px 12px;
		border-radius: 50px;
		transition: all 0.3s ease;

		&:hover {
			background: var(--sWhite);
		}

		&:not(:last-child):after {
			content: "";
			position: absolute;
			top: 50%;
			left: 100%;
			transform: translate(3px, -50%);
			height: 10px;
			width: 1px;
			background: var(--borderColor);
		}

		.icon {
			position: relative;
			background: #c5c6cc;
			position: relative;
			width: 16px;
			height: 16px;
			overflow: hidden;
			border-radius: 20px;

			&::after {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				height: 5px;
				width: 10px;
				border-left: 2px solid var(--sWhite);
				border-bottom: 2px solid var(--sWhite);
				transform: translate(-50%, -3px) rotate(-45deg) scale(0);
				transition: all 0.3s ease;
			}
		}

		.label {
			color: #71727a;
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

		&.active {
			background: var(--sWhite);

			.icon {
				background: var(--sPrimary);

				&::after {
					transform: translate(-50%, -3px) rotate(-45deg) scale(1);
				}
			}

			.label {
				color: #1f2024;
			}
		}
	}
}
