// COLOR
$primary: #091b44;
$green: #8cc152;
$error: #e9573f;
$dark: #333;

// Additional
$secondary: #b6bccd;
$success: #6b8b48;
$barChart: #407fdc;
$RollingStone: #777b82;
$Akaroa: #d5b9a7;
$AthensGray: #f6f6f7;
$white: #ffffff;
$Raven: #717c8c;
$AthensGray200: #e6e9ed;
$Iron: #d7d8da;
$Mischka: #cdd1dd;
$HawkesBlue: #e8f0fd;
$Spindle: #b3c3ee;
$Spindle200: #b2c3ee;
$black: #000000;
$AthensGrayDark: #0b1528;
$DarkDark: #010b1e;
$DarkText: #dcdcdc;
$DarkPrimaryHover: #223668;
$ErrorLight: #c07367;
$CheckedSuccess: #8abf51;
$BlueLink: #3c50e0;

// FONT
$font-size: 16px;
$font-family: PT Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
  Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

// OTHER
$containerWidth: 1440px;
