.uploadWrapper {
  :global {
    .ant-upload-list.ant-upload-list-picture {
      display: flex;
      flex-wrap: wrap;
    }
    .ant-upload-list-item {
      width: 180px;
      margin-inline-end: 8px;
    }
    .ant-upload-list-item-image {
      object-fit: cover !important;
    }
  }
}
