.wrapper {
  margin-bottom: 30px;

  .slider {
    .slide {
      &Content {
        width: 100%;
        min-height: 377px;
        overflow: hidden;
        border-radius: 10px;
      }
      &Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  :global {
    .swiper {
      .swiper-pagination {
        display: flex;
        justify-content: flex-end;
        padding-inline: 13px;
        padding-bottom: 3px;
      }

      .swiper-pagination-inner {
        border-radius: 6.969px;
        background: #00000081;
        color: #fff;
        font-size: 20px;
        line-height: 16px;
        padding: 5px 11px;
      }
    }
  }
}
